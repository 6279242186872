#root {
  min-height: 100vh;
}
.main-body-content {
  position: fixed;
  width: 100%;
  height: calc(100% - 36.5px);
  overflow: auto;
}
/* @media (max-width: 600px) {
  .main-body-content {
    height: calc(100% - 60px);
  }
} */
.sm-datepicker div {
  max-height: 40px;
}
.centered-component {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  border: 4px solid #cccc; /* Light grey */
  border-top: 4px solid #90caf9; /* Blue */
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 2s linear infinite;
}
.custom-tab-container .custom-tab-button:not(:last-child) {
  border-right: 1px solid #2f3545 !important;
}
.custom-tab-button {
  flex: 1;
  padding: 12px;
  background-color: #14161f;
  color: white;
  border-bottom: 1px solid #2f3545 !important;
}
.custom-tab-button-active {
  background-color: #5380cc !important;
  color: white !important;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.button {
  cursor: pointer;
  border: 0;
}
.button:disabled {
  cursor: not-allowed;
  /* background-color: #ffffff; */
}
.PositionTab .MuiTab-root {
  border: 1px solid #2f3545;
  background-color: #121212;
  border-bottom: 0px;
}
.PageTab .MuiTab-root {
  border: 1px solid #2f3545;
  background-color: #121212;
  border-bottom: 0px;
  border-top: 0px;
  height: 36.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.PositionTab .Mui-selected,
.PageTab .Mui-selected {
  background-color: #5380cc;
  color: white !important;
}
.PositionTab,
.PageTab,
.AnalyticsTab *,
.PNLTab *,
.ExchangeListTabs *,
.StrategyBoardTabs * {
  border-color: #2f3545 !important;
}
.ExchangeListTabs > div,
.PositionTab > div,
.PageTab > div,
.AnalyticsTab > div,
.PNLTab > div {
  min-height: 30px;
}
.sm-font-dropdown .MuiSelect-select {
  font-size: 0.9rem;
}
.dense-tabs {
  min-height: 30px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.PositionTab .MuiTabs-indicator,
.PageTab .MuiTabs-indicator,
.AnalyticsTab .MuiTabs-indicator,
.PNLTab .MuiTabs-indicator,
.ExchangeListTabs .MuiTabs-indicator,
.StrategyBoardTabs .MuiTabs-indicator {
  display: none;
}
.positiontables .MuiDataGrid-columnHeaders {
  background-color: #14161f;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: normal !important;
}
.positiontables .css-t89xny-MuiDataGrid-columnHeaderTitle {
  font-weight: normal !important;
}
.positiontables,
.positiontables * {
  border-color: #2f3545 !important;
}
.pair-scanner-grid {
  flex: 10%;
  margin-right: 6%;
}
.forecasting-model {
  margin-right: 20%;
  margin-left: 20%;
}
@media (max-width: 600px) {
  .forecasting-model {
    margin-right: 0;
    margin-left: 0;
  }
}

.ExchangeListTabs .Mui-selected,
.StrategyBoardTabs .Mui-selected,
.AnalyticsTab .Mui-selected,
.PNLTab .Mui-selected,
.active-page {
  background-color: #5380cc !important;
  color: white !important;
}
.StrategyBoardTabs .MuiTabs-root {
  min-height: 0;
}
.ExchangeListTabs .MuiButtonBase-root:hover,
.StrategyBoardTabs .MuiButtonBase-root:hover {
  background-color: #4a4a4a !important;
}
.ExchangeListTabs .Mui-selected:hover,
.StrategyBoardTabs .Mui-selected:hover,
.AnalyticsTab .Mui-selected:hover,
.PNLTab .Mui-selected:hover {
  background-color: #5380cc !important;
}
.AnalyticsTab .MuiButtonBase-root,
.PNLTab .MuiButtonBase-root,
.ExchangeListTabs .MuiButtonBase-root,
.StrategyBoardTabs .MuiButtonBase-root {
  border-left: 1px solid #666666;
}
.PNLTab > .PNLTab-exchanges .MuiButtonBase-root,
.AnalyticsTab .MuiButtonBase-root {
  border-bottom: 1px solid #666666;
}
.AnalyticsTab .MuiButtonBase-root:last-child,
.PNLTab .MuiButtonBase-root:last-child,
.ExchangeListTabs .MuiButtonBase-root:last-child,
.StrategyBoardTabs .MuiButtonBase-root:last-child {
  border-right: 1px solid #666666;
}
.mountable-drawer {
  padding: 16px;
  position: absolute;
  border: 1px solid rgba(81, 81, 81, 1);
  border-right: 0px;
  background-color: rgb(20, 23, 32);
  width: 100%;
  height: 100%;
  z-index: 100;
  animation: 0.5s ease-in slide-left;
}
@keyframes slide-left {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0);
  }
}

.hidden_number_arrow::-webkit-outer-spin-button,
.hidden_number_arrow::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
.hidden_number_arrow[type="number"] {
  appearance: textfield !important;
  -moz-appearance: textfield !important;
}
.pnlTotalsBoxesContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.pnlTotalsBoxes {
  padding: 16px;
  padding-right: 32px;
  padding-left: 32px;
  background-color: rgb(20, 23, 32);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 32px;
  margin-top: 32px;
}
.pnlTotalsBoxes h2 {
  margin-top: 0;
  margin-bottom: 0;
}
.pnlTotalsBoxes .subtitle {
  color: #c2c2c2;
}
.pnlTotalsBoxesLastItem {
  margin-right: 0;
}

.calendar {
  width: 100%;
  border-spacing: 0px;
}
.calendar thead {
  background-color: #14161f;
}

.calendar th {
  font-weight: normal !important;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 8px;
  padding-left: 8px;
  min-width: 125px;
  width: 125px;
  border-bottom: 2px solid #2f3545;
  color: rgba(255, 255, 255, 0.7);
}
.calendar td {
  min-width: 125px;
  width: 125px;
  padding: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #2f3545;
}

.calendar .week-column {
  vertical-align: middle;
  text-align: center;
  min-width: 75px;
  width: 75px;
}
.calendar > tfoot > tr > td {
  text-align: center;
}
.tableheader {
  background-color: #272727;
  border: 1px solid rgba(81, 81, 81, 1);
  border-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
